import React from 'react';
import CityGuide from '../../../../components/guides/CityComponent';
import Footer from '../../../../components/FooterComponent';

import { Glasgow } from '../../../../guides/countries/scotland/glasgow';

function GlasgowPage(props) {
    return (
        <div>
            <CityGuide city={ Glasgow } pathname={ props.location.pathname } urlBack="/travel-guides/europe/scotland" />
            <Footer />
        </div>
    );
}

export default GlasgowPage;
