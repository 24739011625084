import React from 'react';

export const Glasgow = {
    "name": "Glasgow",
    "link": "glasgow",
    "country": "Scotland",
    "continent": "Europe",
    "thumb": "https://live.staticflickr.com/65535/50848717716_15fb6d68bd_t.jpg",
    "image": "https://live.staticflickr.com/65535/50848717716_38b1e2003e_o.jpg",
    "caption": "The city of the Urban Music",

    "description": "Glasgow is the largest city in Scotland and is considered a City of Music by UNESCO. The city stages an average of 130 musical events each week, and is also famous for its vibrant arts, culture, rich architectural heritage and for being a great shopping destination.",

    "nrDays":<p>
        Glasgow is a big city, but from the tourist point of view it has less to see than Edinburgh.
        As such, the city can be visited calmly in <b>2 days</b>. If you only have a few hours, avoid museums
        and get to know more of the city streets.
        <br/><br/>
        However, <b>Culzean Castle</b> and its gardens can make a fantastic day trip from Glasgow.
        If you also decide to visit it, we recommend that you dedicate <b>3 days</b> to the entire stay.
    </p>,

    "whenVisit": <p>
        As in the rest of Scotland, the weather may not always be very friendly, it is usually gray, cold and rainy.
        In the months of October to January it rains a lot, but it is not cold enough to have snow. What will really
        bother you is the wind, so remember to bring a windbreaker, gloves, scarf and cap.
        <br/><br/>
        In December and January there are only 7 hours of light, which makes the days very short for you to enjoy the city.
        As such, to avoid the high season, we recommend visiting the city in <b>May</b> and <b>early June</b> or in <b>September</b>.
    </p>,

    "mobility": <p>
        The city has a <b>subway network</b> and <b>buses</b> that circulate throughout the city. You can pay with a contactless card directly
        on the bus or take 1.70£ in coins to pay for the ticket (as in Edinburgh, they normally don't have change). The daily
        ticket costs £4 and is recommended if you intend on using the public transport extensively. If you arrive by train make sure you get off
        at the right stop, as Glasgow has two stops at quite a distance from each other.
    </p>,

    "safety": <p>
        Glasgow, like the rest of Scotland, <b>is pretty safe</b>.
        It is very similar to any other big city: it has safe areas and less safe areas, so if you are used to live in a
        big city, it is simple - do not do here what you would not do in your city and everything will be fine.
        Glasgow city centre is the safest place and tourists can safely travel almost anywhere without fear of being mugged.
    </p>,

    "itinerary": {
        "description": "As we suggested before, 2 days are enough to check all the main spots. However, we will show a 3 day itinerary, with the last day being dedicated to the to CulZean Castle.",
        "days": [
            {
                "description": <p>
                   Start the first day in the morning with a visit to <b>George Square</b>. It offers free guided tours at 10:30 am,
                   which we recommend because the interior is absurdly beautiful. As it should be, there are always Scots
                   playing in this square. After proceed to the <b>Buchanan Street</b>, a huge road full of shops (and where Dr. Who's famous
                   phone booth is located), at the end you will enter the <b>Gallery of Modern Art</b>.
                   <br/><br/>
                   After grabbing lunch in the area, we suggest you pay a visit to the <b>Glasgow Cathedral</b>. Here you will be able to find
                   the tomb of the famous <b>William Wallace</b>! Right next to the Cathedral is the <b>Necropolis</b>,
                   one of the most visited cemeteries in Europe.
                </p>,
                "image": "https://live.staticflickr.com/65535/50847994823_323152d7a1_o.jpg",
                "caption": "Interior of Glasgow Cathedral."
            },
            {
                "description": <p>
                    For the second day, nothing better than starting very early with a walk through <b>Kelvingrove Park</b>.
                    With lots of animals to admire, this park is right next to the <b>Kelvingrove Museum and Art Gallery</b>,
                    which is also worth a visit. Following the path you will come to admire the famous <b>University of Glasgow</b>.
                    <br/><br/>
                    For the afternoon you can save a visit to the <b>Riverside Museum</b> or the <b>Glasgow Science Center</b>.
                    Both are good attractions and close at 5pm. At late afternoon, there is nothing better
                    than taking a walk on the <b>Murals Trail</b>.
                </p>,
                "image": "https://live.staticflickr.com/65535/50847994808_402ec9ec49_o.jpg",
                "caption": "Kelvingrove Museum and Art Gallery."
            },
            {
                "description": <p>
                    As previously mentioned, <b>Culzean Castle</b> is without a doubt a place worth visiting.
                    As it is located at quite a distance from the city, the best thing to do is to rent a car or
                    buy a day trip with transportation. In the area there will be plenty of activities for the day with a
                    castle and a huge comprehensive area to explore.
                </p>,
                "image": "https://live.staticflickr.com/65535/50847994783_2d7cc2a619_o.jpg",
                "caption": "Culzean Castle."
            }
        ]
    },

    "highlights": [
        {
            "name": "Gallery of Modern Art",
            "description": <p>
                Close to Buchanan is the Gallery of Modern Art (GoMA) on the Royal Exchange Square. It has temporary exhibitions
                and offers events with artists.
                In front is the statue of <b>Duke of Wellington</b> with a traffic cone on his head. According to the story,
                some guys at dawn kept putting the cone on the statue's head for over 30 years! After removing the cones so many times, they decided
                to incorporate the accessory into the statue.
            </p>,
            "image": "https://live.staticflickr.com/65535/50848717981_f24fc72f8f_o.jpg"
        },
        {
            "name": "Glasgow Cathedral",
            "description": <p>
                Being one of the oldest great cathedrals in the United Kingdom, this Cathedral has been in use for over 800 years.
                Its stained glass windows are wonderful and have endured world wars. Admission is free and you only know the size of it
                when you enter. Inside, you will see beautiful arches and the tomb of one of Scotland's heroes, <b>Sir William Wallace</b>
                as well as that of the famous missionary <b>Saint Mungo</b>!
            </p>,
            "image": "https://live.staticflickr.com/65535/50848806292_c4f426af40_o.jpg"
        },
        {
            "name": "Kelvingrove Park",
            "description": <p>This park is mainly used by residents to relax, take children and animals to play and also have a picnic
                while enjoying the sun. Close to the Kelvin River and covering a giant area of the city, you will even have the opportunity
                to find otters and foxes here!
                <br/><br/>
                The park is also home to the <b>Kelvingrove Museum and Art Gallery</b>, which houses more than 8,000 objects of art,
                natural history, weapons and armor. Open from 10 am to 5 pm every day, except Friday and Sunday when it opens at 11 am.
            </p>,
            "image": "https://live.staticflickr.com/65535/50848806257_25842bf71f_o.jpg"
        },
        {
            "name": "Murals Trail",
            "description": <p>When you look for a photo of Glasgow, you will surely find murals and a lot of art.
                The murals are in several buildings around the historic center and are giant pieces of art, super
                detailed and very colorful. Unique creations that bring the city to life! From the story of the
                “little bird that never flew” to “fellow Glasgow residents” you will have the opportunity to
                appreciate this beautiful art.
            </p>,
            "image": "https://live.staticflickr.com/65535/50848806232_f28b9db59d_o.jpg"
        },
        {
            "name": "Culzean Castle",
            "description": <p>
                Located on the cliffs of Ayrshire, this castle was built with the intention of incorporating everything
                that Count David Kennedy could wish for in his country house. Designed by Robert Adam in the late 18th
                century, this magnificent architectural work is filled with towers and treasures that tell the stories
                of the people who lived here.
            </p>,
            "image": "https://live.staticflickr.com/65535/50848806202_3782971272_o.jpg"
        }
    ],

    "food": {
        "description": <p>
        One of Glasgow's most attractive areas is the West End, a street with several charming houses with vintage shops, restaurants and bars of all kinds. Here you will find a variety of gastronomic quality!
        <br/><br/>
        For a cheap meal you can always look for a good restaurant on <a href="https://www.thefork.com/" target="_blank" rel="noopener noreferrer">The Fork.</a> If you are searching for the best dishes, we give you an idea of what to sample.
        </p>,

        "restaurants": [
            {
                "name": "Tony Macaroni",
                "description": "Warm and friendly reception at the entrance, lovely hostess - the service is efficient and delivered on time.",
                "price": 2,
                "image": "https://live.staticflickr.com/65535/50848718036_5f5da6a154_o.jpg"
            },
            {
                "name": "The Ubiquitous Chip",
                "description": "A super old building in a super busy area with several pubs around. The food is very good., but the place is a bit on the expensive side.",
                "price": 4,
                "image": "https://live.staticflickr.com/65535/50848717871_89db8070c3_o.jpg"
            },
            {
                "name": "Bread Meats Bread",
                "description": "Fills easily, but it's worth it. Good service and delicious hamburger and fries. The restaurant is cozy.",
                "price": 2,
                "image": "https://live.staticflickr.com/65535/50848717831_f7228fd7c3_o.jpg"
            },
            {
                "name": "Stravaigin",
                "description": "A typical Scottish restaurant, with excellent service and exceptional food at average prices.",
                "price": 3,
                "image": "https://live.staticflickr.com/65535/50848717801_5cb6463e01_o.jpg"
            }
        ]
    },


    "stay": {
        "description": <p>
               Glasgow has a lot of accommodation options, from hostels to five star hotels.
               However, it is not a cheap city, so be ready to spend a little more to find a good accommodation on a nice spot in the area.
            <br/><br/>
            Below we give you some suggestions of places to stay for every budget.
        </p>,

        "budget": [
            {
                "name": "Glasgow Youth Hostel",
                "description": "Glasgow Youth Hostel offers very comfortable accommodations overlooking Kelvingrove Park, in Glasgow's lively West End. The hostel offers a wide variety of rooms with private bathrooms and spacious shared accommodation with private lockers.",
                "image": "https://live.staticflickr.com/65535/50848806077_7eae73c0a1_o.jpg",
                "link": "/"
            }
        ],
        "mid": [
            {
                "name": "Point A Hotel Glasgow",
                "description": "Point A Hotel Glasgow is located in the heart of Glasgow, 300 meters from the Royal Concert Hall. All rooms are compact and well designed and have private bathrooms and a powerful shower.",
                "image": "https://live.staticflickr.com/65535/50848806057_d378fd8d3e_o.jpg",
                "link": "/"
            }
        ],
        "high": [
            {
                "name": "Apex City of Glasgow Hotel",
                "description": "The Apex City of Glasgow Hotel is located in the heart of Glasgow's vibrant city centre, where this boutique-style hotel offers free WiFi. The rooms are large and luxurious.",
                "image": "https://live.staticflickr.com/65535/50848717746_09f3f4c24c_o.jpg",
                "link": "/"
            }
        ]
    }
}
